.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
}

main,
iframe {
  width: 200px;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

iframe {
  border: 0;
  height: 108px;
}

input,
textarea,
section {
  width: 100%;
}

textarea {
  resize: vertical;
}

section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

button,
input,
textarea,
label,
footer {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type='radio']:checked + label {
  font-weight: bold;
}

footer {
  text-align: center;
  color: gray;
  font-size: x-small;
}

a {
  text-decoration: none;
}
